/*-===============================
   ACCORDION AREA
===============================-*/
.accordion-area {
  .accordion-wrap {
    margin-top: 30px;
  }
}
/*-=========== ACCORDION ==========-*/
.accordion {
  .card {
    border: none;
    margin-bottom: 10px;
    @include border-radius(4px);
    .card-header {
      background-color: transparent;
      border-bottom: 0;
      padding: 0;
      margin-bottom: 0;
      .btn-link {
        width: 100%;
        text-align: left;
        padding: 12px 20px;
        color: $theme-color;
        text-decoration: none;
        border: 1px solid $color2;
        @include border-radius(4px);
        font-weight: $font-weight-medium;
        position: relative;
        &:before {
          position: absolute;
          content: "\f067";
          top: 50%;
          @include transform(translateY(-50%));
          right: 20px;
          font-family: "Line Awesome Free";
          font-weight: 900;
          font-size: $display-15;
          @include transition(0.3s);
        }
      }
      .btn-link[aria-expanded=true] {
        @include border-radius(4px 4px 0 0);
        &:before {
          content: "\f068";
        }
      }
    }
    .card-body {
      border: 1px solid $color2;
      margin-top: -1px;
      padding: 18px 20px;
      font-size: $default-font-2;
      line-height: 26px;
    }
  }
}

/*-=========== ACCORDION 2 ==========-*/
.accordion-2 {
  .card {
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    background-color: $white;
    .card-header {
      .btn-link {
        &:before {
          content: '\f106';
        }
      }
      .btn-link[aria-expanded=true] {
        &:before {
          content: "\f107";
        }
      }
    }
  }
}
/*-=========== ACCORDION 3 ==========-*/
.accordion-3 {
  .card {
    .card-header {
      .btn-link {
        .la {
          margin-right: 5px;
        }
        &:before {
          content: "\f0d7";
        }
      }
      .btn-link[aria-expanded=true] {
        color: $theme-color-2;
        &:before {
          content: "\f0d8";
        }
      }
    }
  }
}
/*-=========== ACCORDION 4 ==========-*/
.accordion-4 {
  .card {
    .card-header {
      .btn-link {
        border-top: none;
        @include border-radius(0);
        border-right: none;
        border-left: none;
        padding-right: 0;
        padding-left: 0;
        &:before {
          right: 0;
        }
      }
      .btn-link[aria-expanded=true] {
        border-bottom: transparent;
      }
    }
    .card-body {
      border-top: none;
      @include border-radius(0);
      border-right: none;
      border-left: none;
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }
}
/*-=========== ACCORDION 5 ==========-*/
.accordion-5 {
  .card {
    background-color: $theme-color;
    @include border-radius(4px !important);
    .card-header {
      .btn-link {
        border: none;
        color: $white;
        .la {
          margin-right: 5px;
        }
      }
    }
    .card-body {
      border: none;
      color: $white;
    }
  }
}

/*-=========== ACCORDION 6 ==========-*/
.accordion-6 {
  .card {
    background-color: $alter-color-6;
    @include border-radius(4px !important);
    .card-header {
      .btn-link {
        border: none;
        background-color: transparent;
        color: $white;
        .la {
          margin-right: 5px;
        }
      }
    }
    .card-body {
      padding-top: 10px;
      border: none;
      color: $white;
    }
  }
}

/*-=========== ACCORDION 7 ==========-*/
.accordion-7 {
  .card {
    background: #8f42ec;
    background: -moz-linear-gradient(-45deg,  #8f42ec 0%, #17a2b8 100%);
    background: -webkit-linear-gradient(-45deg,  #8f42ec 0%,#17a2b8 100%);
    background: linear-gradient(135deg,  #8f42ec 0%,#17a2b8 100%);
    @include border-radius(4px !important);
    .card-header {
      .btn-link {
        border: none;
        background-color: transparent;
        color: $white;
        .la {
          margin-right: 5px;
        }
      }
    }
    .card-body {
      padding-top: 10px;
      border: none;
      color: $white;
    }
  }
}

/*-=========== FAQ ==========-*/
.faq-area {
  .accordion-wrap {
    margin-top: 56px;
    margin-right: 60px;
    @media #{$tab_device} {
      margin-right: 0;
    }
    @media #{$large_mobile} {
      margin-right: 0;
    }
    @media #{$small_mobile} {
      margin-right: 0;
    }
  }
  .faq-img {
    img {
      width: 100%;
    }
  }
}




