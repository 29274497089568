/*-===============================
   FOOTER AREA
===============================-*/
.footer-area {
  padding-top: 120px;
  position: relative;
  overflow: hidden;
  .footer-svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    fill: $theme-color-6
  }
  .footer-item {
    @media #{$tab_device} {
      margin-bottom: 50px;
    }
    @media #{$large_mobile} {
      margin-bottom: 50px;
    }
    @media #{$small_mobile} {
      margin-bottom: 50px;
    }
    .section-divider {
      margin-top: 15px;
      margin-bottom: 0;
      width: 50px;
      &:after {
        right: 12px;
        width: 8px;
      }
    }
    .logo {
      margin-bottom: 35px;
    }
    .footer__desc {
      line-height: 26px;
    }
    .footer__title {
      font-weight: $font-weight-semi-bold;
      font-size: $display-14;
      margin-top: 10px;
    }
    .user-links {
      margin-top: 30px;
      li {
        a {
          display: block;
          text-transform: capitalize;
          color: $theme-color-4;
          margin-bottom: 10px;
          @include transition(0.3s);
          position: relative;
          font-size: $default-font-2;
          &:after {
            position: absolute;
            content: '';
            top: 7px;
            left: 0;
            width: 5px;
            height: 5px;
            @include border-radius(50%);
            background-color: $theme-color-2;
            @include transition(0.3s);
            opacity: 0;
            visibility: hidden;
          }
          &:hover {
            color: $theme-color-2;
            padding-left: 12px;
            &:after {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
    .footer-contact {
      .contact__text {
        line-height: 26px;
        padding-top: 12px;
        a {
          @include transition(0.3s);
          color: $theme-color-4;
          &:hover {
            color: $theme-color-2;
          }
        }
      }
    }
    .contact-form-action {
      margin-top: 30px;
      .contact__text {
        line-height: 26px;
        margin-bottom: 20px;
      }
      form {
        .form-group {
          .send__btn {
            @media #{$large_mobile} {
              position: inherit;
              line-height: 45px;
              @include border-radius(4px);
              margin-top: 15px;
              top: auto;
              right: auto;
            }
            @media #{$large_mobile_three} {
              position: absolute;
              line-height: 49px;
              @include border-radius(0 4px 4px 0);
              margin-top: 0;
              top: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
  .total-box {
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 25px;
    .total-item {
      .total__num {
        font-weight: $font-weight-semi-bold;
        margin-bottom: 4px;
        font-size: $display-15;
      }
      .total__text {
        font-weight: $font-weight-medium;
        font-size: $default-font-2;
      }
      + .total-item {
        margin-left: 40px;
      }
    }
  }
  .copy-right {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    border-top: 1px solid rgba(127, 136, 151, 0.1);
    @media #{$tab_device} {
      margin-top: 0;
    }
    @media #{$large_mobile} {
      margin-top: 0;
      display: block;
      text-align: center;
    }
    @media #{$small_mobile} {
      margin-top: 0;
      display: block;
      text-align: center;
    }
    .copy__desc {
      font-size: $default-font-2;
      @media #{$large_mobile} {
        margin-bottom: 10px;
      }
      @media #{$small_mobile} {
        margin-bottom: 10px;
      }
      .la-heart {
        color: $theme-color-2;
        animation: heartBeat 1000ms infinite linear;
        font-size: $display-15;
      }
      a {
        color: $theme-color-4;
        &:hover {
          color: $theme-color-2;
        }
      }
    }
    .condition-links {
      li {
        display: inline-block;
        margin-right: 10px;
        position: relative;
        font-size: $default-font-2;
        &:after {
          position: absolute;
          content: '/';
          top: 1px;
          right: -12px;
          font-size: $default-font-5;
        }
        &:last-child {
          margin-right: 0;
          &:after {
            display: none;
          }
        }
        a {
          display: block;
          color: $theme-color-4;
          &:hover {
            color: $theme-color-2;
            text-decoration: underline;
          }
        }
      }
    }
  }
}